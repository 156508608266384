import { Fragment, PropsWithChildren, ReactNode } from 'react';

import CommentBox from './components/CommentBox';
import type { OnSubmitFunc } from '@/types/form';
import type { CommentBoxField } from './types';

interface CommentsProps {
  onSubmit: OnSubmitFunc<CommentBoxField>;
  disableButtons: boolean;
  commentOptions?: ReactNode;
  commentsFirst?: boolean;
  minimalToolbar?: boolean;
}

const Comments = ({
  onSubmit,
  disableButtons,
  commentOptions,
  children,
  commentsFirst,
  minimalToolbar,
}: PropsWithChildren<CommentsProps>) => {
  let order: any[] = [
    <CommentBox
      onSubmit={onSubmit}
      submitButton={{ text: 'Send', disabled: disableButtons }}
      commentOptions={commentOptions}
      minimalToolbar={minimalToolbar}
    />,
  ];

  order = commentsFirst ? [children, ...order] : [...order, children];

  return (
    <div>
      {order.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`comment-order-${index}`}>{item}</Fragment>
      ))}
    </div>
  );
};

export default Comments;
