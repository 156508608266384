import type { MouseEvent } from 'react';
import type { ExtraProps as ReactMarkdownProps } from 'react-markdown';

import Tooltip from '../../tooltip/Tooltip';
import Linkv2 from '../../link/Linkv2';
import { getFootnoteClass } from '../utils';

const Link = ({
  node,
  children,
  footnotesClass: footnotesClassRaw,
  disableLinks,
  disabledLinkTooltip,
  renderExternalLinks,
  ...props
}: Omit<React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'ref'> &
ReactMarkdownProps & {
  footnotesClass?: string;
  disableLinks?: boolean;
  disabledLinkTooltip?: string;
  renderExternalLinks?: boolean;
}) => {
  const isFootnote = node?.properties?.dataFootnoteRef || node?.properties?.dataFootnoteBackref;
  const footnotesClass = footnotesClassRaw ? getFootnoteClass(footnotesClassRaw) : footnotesClassRaw;

  let footnoteHref = props.href;
  let footnoteSelector = props.id;

  if (isFootnote) {
    footnoteHref = `#${footnotesClass}-${props.href?.split('#')[1]}`;
    footnoteSelector = `${footnotesClass}-${props.id}`;
  }

  const clickHandler = (e: MouseEvent<HTMLAnchorElement>) => {
    if (disableLinks) {
      e.preventDefault();
    }
  };

  return (
    <Tooltip
      title={disabledLinkTooltip || 'This link has been disabled'}
      disabled={!disableLinks}
    >
      <Linkv2
        {...props}
        id={footnoteSelector}
        href={footnoteHref}
        style={disableLinks ? { opacity: 0.5 } : {}}
        onClick={clickHandler}
        renderAsInternal={!renderExternalLinks}
      >
        {children}
      </Linkv2>
    </Tooltip>
  );
};

export default Link;
