import {
  type ReactNode, useState, useMemo, useCallback, useRef,
} from 'react';
import { ActionIcon, Box, Group } from '@mantine/core';
import type { FieldError } from 'react-hook-form';
import { useEditor } from '@tiptap/react';

import { unified } from 'unified';
import remarkBreaks from 'remark-breaks';
import remarkUnwrapImages from 'remark-unwrap-images';
import rehypeStringify from 'rehype-stringify';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import rehypeParse from 'rehype-parse';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';

import remarkGfm from './gfm';

import EditorComp from './RichTextEditor';
import Toolbar from './toolbar/Toolbar';
import Tooltip from '../tooltip/Tooltip';
import { Codemirror, type CodemirrorRef } from './codemirror';

import MarkdownIcon from '@/assets/icons/content/markdown.svg';

import { extensions } from './extensions';

interface EditorProps {
  onChange: (...event: any[]) => void;
  content: string;
  extraOptions?: ReactNode;
  error?: FieldError;
  minimalToolbar?: boolean;
  id?: string;
}

const Editor = ({
  onChange,
  content,
  extraOptions,
  error,
  minimalToolbar,
  id,
}: EditorProps) => {
  const [mode, setMode] = useState<'rte' | 'md'>('rte');
  const codemirrorRef = useRef<CodemirrorRef>(null);
  const lockCodemirror = useRef(false);

  const htmlContent = useMemo(() => (
    unified()
      .use(remarkParse)
      .use(remarkBreaks)
      .use(remarkGfm)
      .use(remarkUnwrapImages)
      .use(remarkRehype)
      .use(rehypeStringify)
      .processSync(content)
      .toString()
  ), [content]);

  const onCodemirrorChange = useCallback((getCode: () => string) => {
    const value = getCode();
    onChange(value);
  }, []);

  const toMd = (text: string) => unified()
    .use(rehypeParse)
    .use(remarkBreaks)
    .use(remarkGfm)
    .use(rehypeRemark)
    .use(remarkStringify)
    .processSync(text)
    .toString()
    .replace(/\\\[/g, '[');

  const editor = useEditor({
    extensions,
    content: htmlContent,
    onUpdate({ editor: editorCtx }) {
      onChange(toMd(editorCtx.getHTML()));
    },
  }, [onChange]);

  const chandeMode = () => {
    if (mode === 'rte') {
      const lock = lockCodemirror.current;

      if (lock || !codemirrorRef.current) return;

      setMode('md');
      codemirrorRef.current.update(content);

      return;
    }

    if (!editor) return;

    setMode('rte');
    editor.commands.focus();
    editor.commands.setContent(htmlContent);
  };

  return (
    <div data-testid={id}>
      <Group>
        <Box display={mode === 'rte' ? 'block' : 'none'}>
          <Toolbar editor={editor} extraOptions={extraOptions} minimalToolbar={minimalToolbar} />
        </Box>

        {!minimalToolbar && (
          <Tooltip title={`Edit ${mode === 'rte' ? 'Markdown' : 'Rich Text'}`}>
            <ActionIcon
              ml="auto"
              size={30}
              variant="subtle"
              aria-label="Change Mode"
              onClick={chandeMode}
            >
              <MarkdownIcon />
            </ActionIcon>
          </Tooltip>
        )}
      </Group>
      <Box display={mode === 'rte' ? 'block' : 'none'}>
        <EditorComp
          error={error}
          editor={editor}
        />
      </Box>

      <Box display={mode === 'md' ? 'block' : 'none'} mt={20}>
        <Codemirror
          ref={codemirrorRef}
          content=""
          onChange={onCodemirrorChange}
          lock={lockCodemirror}
        />
      </Box>
    </div>
  );
};

export default Editor;
