import { type PropsWithChildren, type ReactNode } from 'react';
import { Grid, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import List from '@/components/content/list/List';

import ModuleWrapper from './ModuleWrapper';
import IssueModule from './IssueModule';
import Linkv2 from '@/components/content/link/Linkv2';

import { isNotNull, isUndefined } from '@/utils';

import { AUTOORDER, type Module, type ModuleDef, type ModuleLocation } from '../types/modulesv2';

import classes from './IssueModule.module.css';

interface IssueWrapperProps<TData> {
  top?: Array<Module<TData>>;
  left?: Array<Module<TData>>;
  right?: Array<Module<TData>>;
  data: TData;
  actions?: ReactNode;
  removePadding?: boolean;
  id: string | number;
  loading?: boolean;
}

const checkValidValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (value !== null && typeof value === 'object') {
    return Object.keys(value).length !== 0;
  }

  return isNotNull(value) && !isUndefined(value) && value !== '';
};

const DefaultContent = ({
  value,
}: { value: Array<string | number> | string | number }) => {
  if (Array.isArray(value)) {
    return (
      <List items={value} renderItem={(val) => val} />
    );
  }

  if (typeof value === 'string' && value.startsWith('http')) {
    return <Linkv2 href={value} target="_blank" rel="noreferrer">{value}</Linkv2>;
  }

  return (
    <Text>{value}</Text>
  );
};

function resolveModule<TData>(
  data: TData,
  id: string | number,
  m: Module<TData>,
  loading: boolean,
) {
  return {
    ...m,
    submodules: m.submodules
      .map((submodule) => {
        const value = submodule.accessor(data);
        const scroll = typeof submodule.scroll === 'boolean' ? submodule.scroll : true;

        if (!checkValidValue(value)) {
          if (submodule.allowInvalidValue) {
            return {
              ...submodule,
              render: <DefaultContent value={submodule.allowInvalidValue} />,
              scroll,
            };
          }

          return null;
        }

        const render = () => {
          if (submodule.element) {
            const output = <submodule.element getValue={() => value} id={id} loading={loading} />;
            return output;
          }

          return <DefaultContent value={value} />;
        };

        return {
          ...submodule,
          scroll,
          render: render(),
        };
      })
      .filter(isNotNull),
  };
}

function resolveModules<TData>(
  data: TData,
  id: string | number,
  loading: boolean,
  isMD?: boolean,
  modules?: Record<string, Array<Module<TData>> | undefined>,
) {
  if (!modules) return null;

  const arrays = Object.entries(modules);
  const sortedArrays = {
    top: [],
    left: [],
    right: [],
  } as { top: Array<ModuleDef<TData>>, left: Array<ModuleDef<TData>>, right: Array<ModuleDef<TData>> };

  function resolveM(module: Array<Module<TData>>, baseline: ModuleLocation) {
    const baseOrder = { position: AUTOORDER, location: baseline };

    module.forEach((mod) => {
      if (mod.hide) return;

      const resolved = resolveModule(data, id, mod, loading);
      const order = isMD ? mod.order?.md ?? baseOrder : mod.order?.baseline ?? baseOrder;

      if (order.position === AUTOORDER) {
        sortedArrays[order.location].push(resolved);
      } else {
        sortedArrays[order.location].splice(order.position, 0, resolved);
      }
    });
  }

  arrays.forEach(([key, value]) => { if (value) { resolveM(value, key as ModuleLocation); } });

  return sortedArrays;
}

const IssueWrapperV2 = <TData extends unknown>({
  top,
  left,
  right,
  actions,
  data,
  removePadding,
  id,
  children,
  loading,
}: PropsWithChildren<IssueWrapperProps<TData>>) => {
  const matches = useMediaQuery('(min-width: 62em)');

  const resolved = resolveModules(data, id, !!loading, matches, { top, left, right });

  if (!resolved) return null;

  return (
    <Grid
      columns={20}
      classNames={{
        root: 'mercury__grid',
        inner: `${classes.inner} ${removePadding ? classes.noPadding : ''}`,
        col: classes.col,
      }}
    >
      {resolved.top && (
        <Grid.Col span={{ base: 20 }}>
          {resolved.top.map((module, i) => (
            <IssueModule key={module.id || module.title || `issue-module-top-${i}`} module={module} />
          ))}
        </Grid.Col>
      )}

      {resolved.left && (
        <Grid.Col span={{ base: 20, md: 14 }}>
          {!matches && actions && (
            // Actions Block is always available on desktop viewports in the right side and mobile on the left side
            <ModuleWrapper title="Actions" id="actions-box">
              {actions}
            </ModuleWrapper>
          )}

          {resolved.left.map((module, i) => (
            <IssueModule key={module.id || module.title || `issue-module-left-${i}`} module={module} />
          ))}

          {children}
        </Grid.Col>
      )}

      {resolved.right && (
        <Grid.Col span={{ base: 20, md: 6 }}>
          {matches && actions && (
            // Actions Block is always available on desktop viewports in the right side and mobile on the left side
            <ModuleWrapper title="Actions" id="actions-box">
              {actions}
            </ModuleWrapper>
          )}

          {resolved.right.map((module, i) => (
            <IssueModule key={module.id || module.title || `issue-module-right-${i}`} module={module} />
          ))}
        </Grid.Col>
      )}
    </Grid>
  );
};

export default IssueWrapperV2;
