import { gfmAutolinkLiteralFromMarkdown,
  gfmAutolinkLiteralToMarkdown } from 'mdast-util-gfm-autolink-literal';

import { gfmStrikethroughFromMarkdown,
  gfmStrikethroughToMarkdown } from 'mdast-util-gfm-strikethrough';

import { gfmTableFromMarkdown, gfmTableToMarkdown } from 'mdast-util-gfm-table';
import { gfmTaskListItemFromMarkdown,
  gfmTaskListItemToMarkdown } from 'mdast-util-gfm-task-list-item';

import { gfmFootnoteFromMarkdown } from 'mdast-util-gfm-footnote';

import { combineExtensions } from 'micromark-util-combine-extensions';
import { gfmAutolinkLiteral } from 'micromark-extension-gfm-autolink-literal';

import { gfmStrikethrough } from 'micromark-extension-gfm-strikethrough';
import { gfmTable } from 'micromark-extension-gfm-table';
import { gfmTaskListItem } from 'micromark-extension-gfm-task-list-item';

function gfmFromMarkdown() {
  return [
    gfmAutolinkLiteralFromMarkdown(),
    gfmFootnoteFromMarkdown(),
    gfmStrikethroughFromMarkdown(),
    gfmTableFromMarkdown(),
    gfmTaskListItemFromMarkdown(),
  ];
}

function gfmToMarkdown() {
  return {
    extensions: [
      gfmAutolinkLiteralToMarkdown(),
      gfmStrikethroughToMarkdown(),
      gfmTableToMarkdown(),
      gfmTaskListItemToMarkdown(),
    ],
  };
}

function gfm() {
  return combineExtensions([
    gfmAutolinkLiteral(),
    gfmStrikethrough(),
    gfmTable(),
    gfmTaskListItem(),
  ]);
}

export default function remarkGfm() {
  // @ts-expect-error: TS is wrong about `this`.
  const self = /** @type {Processor} */ (this);
  const data = self.data();

  const micromarkExtensions = data.micromarkExtensions || (data.micromarkExtensions = []);
  const fromMarkdownExtensions = data.fromMarkdownExtensions || (data.fromMarkdownExtensions = []);
  const toMarkdownExtensions = data.toMarkdownExtensions || (data.toMarkdownExtensions = []);

  micromarkExtensions.push(gfm());
  fromMarkdownExtensions.push(gfmFromMarkdown());
  toMarkdownExtensions.push(gfmToMarkdown());
}
