/* eslint-disable no-useless-escape */
import { type ClassAttributes, type LiHTMLAttributes, useCallback } from 'react';
import { Text, TextProps, TypographyStylesProvider } from '@mantine/core';
import ReactMarkdown, { ExtraProps, UrlTransform } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeRaw from 'rehype-raw';

import Link from './components/Link';
import MarkdownImage from './components/Image';
import Table from './components/Table';
import Title from './components/Title';
import ListItem from './components/ListItem';

import classes from './Markdown.module.css';

export interface MarkdownProps {
  text: string;
  transformImageUri?: UrlTransform;
  footnotesClass?: string;
  disableLinks?: boolean;
  disabledLinkTooltip?: string;
  disallowedElements?: Array<string>;
  textProps?: TextProps;
  renderExternalLinks?: boolean;
}

const Markdown = ({
  text,
  transformImageUri,
  footnotesClass = '',
  disableLinks,
  disabledLinkTooltip,
  disallowedElements,
  textProps = {},
  renderExternalLinks,
}: MarkdownProps) => {
  const ListItemWithProps = useCallback((
    props: ClassAttributes<HTMLLIElement> & LiHTMLAttributes<HTMLLIElement> & ExtraProps,
  ) => (
    <ListItem
      {...props}
      footnotesClass={footnotesClass}
    />
  ), [footnotesClass]);

  const LinkWithProps = useCallback((props: ExtraProps) => (
    <Link
      {...props}
      footnotesClass={footnotesClass}
      disableLinks={disableLinks}
      disabledLinkTooltip={disabledLinkTooltip}
      renderExternalLinks={renderExternalLinks}
    />
  ), [footnotesClass, disableLinks, disabledLinkTooltip, renderExternalLinks]);

  return (
    <Text component="div" {...textProps}>
      <TypographyStylesProvider
        classNames={{ root: textProps.lineClamp && textProps.lineClamp > 0 ? classes.clampedContainer : '' }}
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[[rehypeExternalLinks, { target: '_blank', rel: ['nofollow'] }], remarkGfm, remarkBreaks]}
          disallowedElements={disallowedElements}
          components={{
            h1: Title,
            h2: Title,
            h3: Title,
            img: MarkdownImage,
            li: ListItemWithProps,
            a: LinkWithProps,
            table: Table,
          }}
          urlTransform={transformImageUri}
        >
          {text}
        </ReactMarkdown>
      </TypographyStylesProvider>
    </Text>
  );
};

export default Markdown;
