import React, { Children, isValidElement, ReactNode } from 'react';
import { ExtraProps } from 'react-markdown';
import { TableVirtuoso } from 'react-virtuoso';

import classes from '../Markdown.module.css';

const Table = ({
  children,
}: React.ClassAttributes<HTMLTableElement> & React.TableHTMLAttributes<HTMLTableElement> & ExtraProps) => {
  const extractElementsByType = (children: ReactNode, type: string) => (
    Children.toArray(children).filter((child) => isValidElement(child) && child.type === type).flatMap((child) => (
      Children.toArray((child as any).props.children).filter((child) => isValidElement(child) && child.type === 'tr')
    ))
  );

  const rows = extractElementsByType(children, 'tbody');
  const headers = extractElementsByType(children, 'thead');
  const rowsHeight = (rows.length + 2) * 45;

  return (
    <TableVirtuoso
      style={{ height: rowsHeight > 500 ? 500 : rowsHeight }}
      data={rows}
      fixedHeaderContent={() => headers}
      className={classes.tableContainer}
      itemContent={(index, row) => (React.isValidElement(row) ? row.props.children : row)}
    />
  );
};

export default Table;
