import type { MutableRefObject } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { createCodeMirrorState, createCodeMirrorView } from './setup';

interface CodemirrorProps {
  content: string;
  onChange: (getString: () => string) => void;
  lock: MutableRefObject<boolean>;
}
export interface CodemirrorRef {
  update: (markdown: string) => void;
}
export const Codemirror = forwardRef<CodemirrorRef, CodemirrorProps>(
  ({ content, onChange, lock }, ref) => {
    const divRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<ReturnType<typeof createCodeMirrorView>>();

    useEffect(() => {
      if (!divRef.current) return undefined;

      const editor = createCodeMirrorView({
        root: divRef.current,
        onChange,
        lock,
        content,
      });
      editorRef.current = editor;

      return () => {
        editor.destroy();
      };
    }, [onChange, content, lock]);

    useImperativeHandle(ref, () => ({
      update: (newContent: string) => {
        const { current } = editorRef;
        if (!current) return;

        const state = createCodeMirrorState({ onChange, lock, content: newContent });
        current.setState(state);
      },
    }));

    return (
      <div
        style={{
          width: '100%',
          backgroundColor: 'var(--grid-column-bg)',
          border: '1px solid var(--default-border)',
          borderRadius: '4px',
        }}
        ref={divRef}
      />
    );
  },
);
Codemirror.displayName = 'Codemirror';
