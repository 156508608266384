/* eslint-disable max-len */
import { Center, Text } from '@mantine/core';

// Components
import Skeleton from '@/components/content/placeholder/Skeleton';
import List from '@/components/content/list/List';

// Hooks
import { useUserId } from '@/hooks/useUserId';
import { useMetadataEventsHistoryInIssueQuery } from '../services/alertsHistory';

import { toDateTime } from '@/utils/toDate';
import { capitalize } from '@/utils/string';

const AlertsHistory = ({ value }: { value: number }) => {
  const userId = useUserId();
  const { isPending, isError, error, data = [] } = useMetadataEventsHistoryInIssueQuery(userId, value);

  if (isError && error.code !== 204) {
    return null;
  }

  if (isPending) {
    return (
      <Skeleton height={45} />
    );
  }

  return (
    <>
      {data.length > 0 ? (
        <List
          items={data}
          renderItem={(event) => (
            <>
              <b>{capitalize(event.activity)}</b> by {event.first_name} {event.last_name} on {toDateTime(event.created_at)}
            </>
          )}
        />
      ) : (
        <Center>
          <Text size="xl">No actions taken</Text>
        </Center>
      )}
    </>
  );
};

export default AlertsHistory;
