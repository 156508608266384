import { ActionIcon } from '@mantine/core';
import type { QueryKey } from '@tanstack/react-query';

import Button from '@/components/content/button/Button';
import Tooltip from '@/components/content/tooltip/Tooltip';

import { useQueryActions } from '@/hooks/useQueryActions';

import type { MutationOptions } from '@/types/react-query';

import AcknowledgeIcon from '@/assets/icons/content/acknowledge.svg';
import { issuesKeys } from '../../issue/services/keys';
import { METADATA_TYPE, useMetadata } from '../services/metadata';
import { useToast } from '@/hooks/useToast';

interface AcknowledgeProps {
  issueId: number;
  isAcknowledged: boolean;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
  size?: number;
  mutationOptions?: MutationOptions<unknown>;
  invalidate?: Array<QueryKey>;
}

const Acknowledge = ({
  issueId,
  isAcknowledged,
  asButton,
  loading,
  disabled,
  size = 20,
  mutationOptions = {},
  invalidate = [],
}: AcknowledgeProps) => {
  const actions = useQueryActions();
  const mutation = useMetadata(issueId, METADATA_TYPE.ACKNOWLEDGED, isAcknowledged, mutationOptions);
  const { createToast } = useToast();

  const disableAcknowledge = disabled || loading || mutation.isPending || mutation.isError;
  const isLoading = loading || mutation.isPending;
  const text = isAcknowledged ? 'Unacknowledge' : 'Acknowledge';

  const onAcknowledge = () => {
    mutation.mutate('', {
      onSuccess: () => {
        actions.invalidateQueries([
          issuesKeys.metadataHistory(issueId),
          ...invalidate,
        ], { type: 'all' });
      },
      onError: (error) => {
        createToast(error.message, 'error');
      },
    });
  };

  return (
    <>
      {asButton ? (
        <Button
          onClick={onAcknowledge}
          color="var(--quo-green)"
          size="sm"
          loading={isLoading}
          disabled={disableAcknowledge}
          rightSection={<AcknowledgeIcon width={15} height={15} />}
        >
          {text}
        </Button>
      ) : (
        <Tooltip title={text}>
          <ActionIcon
            size={size}
            variant="subtle"
            aria-label={text}
            disabled={disableAcknowledge}
            color={isAcknowledged ? 'var(--quo-green)' : ''}
            loaderProps={{ size: size - 4 }}
            loading={isLoading}
            onClick={onAcknowledge}
          >
            <AcknowledgeIcon />
          </ActionIcon>
        </Tooltip>
      )}
    </>
  );
};

export default Acknowledge;
