import type { ChangeEvent } from 'react';

import { Checkbox } from '@mantine/core';

interface CommentOptionsProps {
  options: Record<string, unknown>
  setOption: (e: ChangeEvent<HTMLInputElement>) => void
}

const CommentOptions = ({ options, setOption }: CommentOptionsProps) => (
  <Checkbox
    type="checkbox"
    onChange={setOption}
    value={String(options.request_support)}
    label="Request Support"
    name="request_support"
    id="request_support"
    size="xs"
    checked={!!options.request_support}
  />
);

export default CommentOptions;
