import { ChangeEvent, useState } from 'react';
import produce from 'immer';

import { useQueryActions } from '@/hooks/useQueryActions';
import { deepArrFind } from '@/utils/deepArrFind';
import { useUserId } from '@/hooks/useUserId';
import { issuesKeys } from '../services/keys';

import { useIssueCommentsMutation } from '../services/commentMutation';

import type { OnSubmitFunc } from '@/types/form';
import type { Comment } from '../types';
import type { CommentBoxField } from '../../comments/types';

interface UseCommentProps {
  id: number | null;
  parent?: number;
  issueId: number;
  title: string;
  owner: number | null;
}

export const useComment = ({ id, parent, title, owner, issueId }: UseCommentProps) => {
  const { setData, onMutate: optimisticUpdate } = useQueryActions();
  const [commentOptions, setCommentOptions] = useState<Record<string, unknown>>({});
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const userId = useUserId();
  const isOwner = userId === owner;

  const { mutate: postComment, isPending: isPostingNote } = useIssueCommentsMutation(
    'POST',
    issueId,
    userId,
  );

  const { mutate: putComment, isPending: isUpdatingNote } = useIssueCommentsMutation(
    'PUT',
    issueId,
    userId,
    {
      onMutate: (newData: any) => optimisticUpdate(issuesKeys.comments(issueId), (old: any) => (
        produce(old, (draftState: any) => {
          const comment = id && deepArrFind<'children', Comment>(draftState, id, 'children');

          if (comment) {
            comment.note = newData.note;
          }
        })
      )),
    },
    id,
  );

  const { mutate: deleteComment } = useIssueCommentsMutation('DELETE', issueId, userId, {}, id);

  const addComment: OnSubmitFunc<CommentBoxField> = ({ comment: note }, setGeneralError, success, reset) => {
    postComment(
      { issue_title: title, parent_id: id, note, ...commentOptions },
      {
        onSuccess: (newData: any) => {
          setData<Comment[]>(issuesKeys.comments(issueId), (old) => produce(old, (draftState) => {
            const state = draftState || [];
            const comment = id ? deepArrFind<'children', Comment>(state, id, 'children') : null;

            if (comment) {
              comment.children.push(newData);
            } else {
              if (!state.length) {
                return [newData];
              }

              state.push(newData);
            }

            return state;
          }));

          setIsReplying(false);
          reset();
        },
        onError: () => {
          setGeneralError('Sorry we are having some problems adding a new comment right now.');
        },
      },
    );
  };

  const editComment: OnSubmitFunc<CommentBoxField> = ({ comment: note }, setGeneralError) => {
    putComment(
      { issue_title: title, parent_id: parent, note },
      {
        onSuccess: () => {
          setIsEditing(false);
        },
        onError: (err, newNote, context: any) => {
          setGeneralError('Sorry we are having some problems editing this comment right now.');

          setData(issuesKeys.comments(issueId), () => context.previousData);
        },
      },
    );
  };

  const removeComment = () => {
    deleteComment(
      {},
      {
        onSuccess: () => {
          setData<Comment[]>(issuesKeys.comments(issueId), (old) => old && produce(old, (draftState) => {
            if (parent) {
              const comment = deepArrFind<'children', Comment>(draftState, parent, 'children');

              if (comment) {
                comment.children = comment.children.filter((com) => com.id !== id);
              }
            } else {
              return draftState.filter((com) => com.id !== id);
            }

            return draftState;
          }));
        },
      },
    );
  };

  const setCommentOption = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    setCommentOptions((state) => ({ ...state, [name]: !state[name] }));
  };

  const disableButtons = isPostingNote || isUpdatingNote;

  return {
    addComment,
    editComment,
    removeComment,
    disableButtons,
    commentOptions,
    setCommentOption,
    isReplying,
    setIsReplying,
    isEditing,
    setIsEditing,
    allowEdit: isOwner,
    allowRemove: isOwner,
  };
};
