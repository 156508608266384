import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import Linkv2 from '@/components/content/link/Linkv2';
import DrawerLink from './DrawerLink';
import IssueWrapperV2 from '../issue/components/IssueWrapper';
import RouterLink from '@/components/content/link/RouterLink';

import { useToast } from '@/hooks/useToast';
import { useGetDrawerData, type QueryOptions } from './services/get';

import type { Module } from '../issue/types/modulesv2';

interface DeferredDrawerLinkProps<TData = unknown, TSelect = TData> {
  value: string;
  title?: string;
  query: QueryOptions<TData, TSelect>;
  content: Array<Module<TSelect>>;
  link?: string;
  disableLink?: boolean;
  noPrint?: boolean;
  id: number | string;
}

const DeferredDrawerLink = <TData = unknown, TSelectData = TData>({
  value,
  title,
  content,
  query,
  link,
  id,
  disableLink,
  noPrint,
}: DeferredDrawerLinkProps<TData, TSelectData>) => {
  const [enabled, setEnabled] = useState(false);
  const queryClient = useQueryClient();
  const { createToast } = useToast();

  const drawerQuery = useGetDrawerData(query, enabled);

  if (drawerQuery.isSuccess && enabled) {
    return (
      <DrawerLink
        id={id}
        value={value}
        title={title}
        noPrint={noPrint}
        link={(
          link && !disableLink
            ? <RouterLink to={link}>See more</RouterLink>
            : null
        )}
        content={(
          <IssueWrapperV2
            top={content}
            data={drawerQuery.data}
            id={id}
            removePadding
          />
        )}
        initialOpen
      />
    );
  }

  const onClick = () => {
    setEnabled(true);

    if (drawerQuery.isError) {
      createToast(drawerQuery.error.message, 'warning');
    }
  };

  const prefetchCatalog = () => {
    if (drawerQuery.isError) return;

    queryClient.prefetchQuery(query);
  };

  return (
    <Linkv2
      onMouseEnter={prefetchCatalog}
      onClick={onClick}
      as="button"
      type="button"
      disabled={drawerQuery.isLoading && enabled}
      animate={drawerQuery.isLoading && enabled}
      noPrint={noPrint}
    >
      {value}
    </Linkv2>
  );
};

export default DeferredDrawerLink;
