import { ActionIcon } from '@mantine/core';
import type { QueryKey } from '@tanstack/react-query';

import Button from '@/components/content/button/Button';
import Tooltip from '@/components/content/tooltip/Tooltip';

import { useQueryActions } from '@/hooks/useQueryActions';

import type { MutationOptions } from '@/types/react-query';
import { issuesKeys } from '../../issue/services/keys';

import ResolveIcon from '@/assets/icons/content/resolved.svg';
import { METADATA_TYPE, useMetadata } from '../services/metadata';
import { useToast } from '@/hooks/useToast';

interface ResolveProps {
  issueId: number;
  isResolved: boolean;
  asButton?: boolean;
  loading?: boolean;
  disabled?: boolean;
  mutationOptions?: MutationOptions<unknown>;
  invalidate?: Array<QueryKey>;
}

const Resolve = ({
  issueId,
  isResolved,
  asButton,
  loading,
  disabled,
  mutationOptions = {},
  invalidate = [],
}: ResolveProps) => {
  const actions = useQueryActions();
  const mutation = useMetadata(issueId, METADATA_TYPE.RESOLVED, isResolved, mutationOptions);
  const { createToast } = useToast();

  const disableResolved = disabled || loading || mutation.isPending || mutation.isError;
  const isLoading = loading || mutation.isPending;
  const text = isResolved ? 'Unresolve' : 'Resolve';

  const onResolve = () => {
    mutation.mutate('', {
      onSuccess: () => {
        actions.invalidateQueries([
          issuesKeys.metadataHistory(issueId),
          ...invalidate,
        ]);
      },
      onError: (error) => {
        createToast(error.message, 'error');
      },
    });
  };

  return (
    <>
      {asButton ? (
        <Button
          onClick={onResolve}
          color="var(--quo-blue-75)"
          size="sm"
          loading={isLoading}
          disabled={disableResolved}
          rightSection={<ResolveIcon width={20} height={20} />}
        >
          {text}
        </Button>
      ) : (
        <Tooltip title={text}>
          <ActionIcon
            size={24}
            variant="subtle"
            aria-label={text}
            disabled={disableResolved}
            loaderProps={{ size: 20 }}
            loading={isLoading}
            onClick={onResolve}
          >
            <ResolveIcon />
          </ActionIcon>
        </Tooltip>
      )}
    </>
  );
};

export default Resolve;
