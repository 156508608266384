import { type QueryKey, keepPreviousData, useQuery } from '@tanstack/react-query';

import { fetchData } from '@/lib/fetch';
import { stringifyParams } from '@/utils/stringifyParams';

import type { FetchError } from '@/types/api';
import type { OpenSearchParams } from '@/types/mercury-data-types/opensearch';

interface ResultQueryArgs<TResponse = unknown, TData = TResponse> {
  endpoint: string;
  queryKey: QueryKey;
  params?: Partial<OpenSearchParams>;
  enabled?: boolean;
  select?: (data: TResponse) => TData;
}

const getParams = (params: Partial<OpenSearchParams>) => ({
  ...params,
  start: ((params?.start || 1) - 1) * (params?.length || 0),
});

function openSearchQuery<TResponse = unknown, TData = TResponse>({
  endpoint,
  queryKey,
  params,
  select,
  enabled = true,
}: ResultQueryArgs<TResponse, TData>) {
  const queryKeyWithParams = [...queryKey, ...(params ? [params] : [])];
  const urlParams = params && stringifyParams(getParams(params));

  return {
    queryKey: queryKeyWithParams,
    queryFn: () => (
      fetchData({ endpoint: `${endpoint}${urlParams ? `?${urlParams}` : ''}` })
    ),
    ...(select && { select }),
    placeholderData: keepPreviousData,
    enabled,
  };
}

const useOpenSearchQuery = <TResponse = unknown, TData = TResponse>(
  query: ResultQueryArgs<TResponse, TData>,
) => (
    useQuery<TResponse, FetchError, TData>(openSearchQuery(query))
  );

export const useQiQlQuery = useOpenSearchQuery;

export const useMetisQuery = useOpenSearchQuery;
